<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            Вход
          </h2>
        </b-link>

        <!-- form -->
        <b-overlay :show="loading">
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <b-form-group
                label-for="login"
                :label="$t('Логин')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Login"
                  rules="required"
                >
                  <b-form-input
                    id="login"
                    v-model="login"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Логин')"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="password"
                :label="$t('Пароль')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Пароль')"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Пароль')"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
                @click="checkFieldsFilled"
              >
                {{ $t('Войти') }}
              </b-button>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      login: '',
      password: '',
      status: '',
      loading: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({ user: 'auth/USER' }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({ loginAuth: 'auth/login' }),
    submitLogin() {
      this.loading = true
      const data = {
        login: this.login,
        password: this.password,
      }
      this.loginAuth(data)
        .then(() => {
          // localStorage.setItem('settings', skin)
          this.redirectTo()
          // this.$router.push({ name: 'ClientCreate' })
        })
        .catch(err => {
          this.showLoginError(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkFieldsFilled() {
      if (this.validate()) {
        this.submitLogin()
      } else {
        this.showLoginError('empty')
      }
    },
    validate() {
      return !!((this.login.length > 0 && this.password.length > 0))
    },
    showLoginError(type) {
      let message = ''
      if (type === 'empty') {
        message = 'Логин ёки парол киритилмади'
      } else {
        message = type.data ? type.data.message : 'Server xatoligi'
      }
      showToast('danger', message)
    },
    redirectTo() {
      if (this.user.role.name) {
        switch (this.user.role.name) {
          case 'cashier':
            this.$router.push({ name: 'OrdersIndex' })
            break
          case 'levier':
            this.$router.push({ name: 'OrdersIndex' })
            break
          case 'seller':
            this.$router.push({ name: 'ClientCreate' })
            break
          case 'deputy':
            this.$router.push({ name: 'OrdersIndex' })
            break
          case 'operator':
            this.$router.push({ name: 'OrdersIndex' })
            break
          case 'director':
            this.$router.push({ name: 'OrdersIndex' })
            break
          case 'images_checker':
            this.$router.push({ name: 'ConfirmContractPhotos' })
            break
          case 'icloud':
            this.$router.push({ name: 'iCloudList' })
            break

          default:
            this.goTo('ScanerProduct')
            break
        }
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
